const initialState = {
  state: {},
  categories: []
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ARTICLES_INFO_SUCCESS':
      const id = action?.payload?._id;
      return { ...state, state: { ...state.state, [id]: action.payload } };
    case 'GET_ARTICLES_CATEGORIES_INFO_SUCCESS':
      return { ...state, categories: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
