import React from 'react';
import { IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { styled } from '@mui/system';

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  bottom: 30,
  right: 30,
  zIndex: 1000,
  [theme.breakpoints.down('sm')]: {
    bottom: 20,
    right: 20
  },
  '& svg': {
    width: 40,
    height: 40,
    color: theme.palette.primary.main
  }
}));

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = React.useState(false);

  const handleScrollToTop = React.useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  React.useEffect(() => {
    const handleScroll = () => setIsVisible(window.scrollY > 100);

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <IconButtonWrapper onClick={handleScrollToTop} aria-label="scroll to top">
      <ArrowUpwardIcon />
    </IconButtonWrapper>
  );
};

export default ScrollToTop;
