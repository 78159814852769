import dispatchType from 'services/dataList/dispatchType';
import mapDataDefault from 'services/dataList/mapDataDefault';

import merge from 'lodash/fp/merge';
import objectPath from 'object-path';
import { COLUMNS_LIMIT_DEFAULT } from 'variables/main';

const GET_LIST = 'GET_LIST';
const ON_ERROR_CLOSE = 'ON_ERROR_CLOSE';
const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';
const GET_LIST_FAIL = 'GET_LIST_FAIL';

const CLEAR_FILTERS = 'CLEAR_FILTERS';

const ON_CHANGE_PAGE = 'ON_CHANGE_PAGE';
const ON_SEARCH_CHANGE = 'ON_SEARCH_CHANGE';
const ON_FILTER_CHANGE = 'ON_FILTER_CHANGE';
const COLUMN_SORT_CHANGE = 'COLUMN_SORT_CHANGE';
const ON_CHANGE_ROWS_PER_PAGE = 'ON_CHANGE_ROWS_PER_PAGE';

const initialState = () => ({
  loading: false,
  error: null,
  count: null,
  page: null,
  perPage: COLUMNS_LIMIT_DEFAULT,
  data: null,
  filters: {},
  search: '',
  sort: {}
});

const dataTableReducer =
  (endPoint) =>
  (state = merge(initialState(endPoint), endPoint.defaultOptions), action) => {
    const { sourceName, mapData, reduce } = endPoint;

    if (reduce) {
      state = reduce(state, action);
    }

    switch (action.type) {
      case dispatchType(sourceName, CLEAR_FILTERS):
        return initialState(endPoint);
      case dispatchType(sourceName, GET_LIST):
        return { ...state, loading: true };
      case dispatchType(sourceName, ON_ERROR_CLOSE):
        return {
          ...state,
          errors: state.errors.filter((_, index) => index !== action.payload)
        };
      case dispatchType(sourceName, GET_LIST_SUCCESS):
        return {
          ...state,
          ...(mapData || mapDataDefault)(action.payload, state),
          loading: false
        };
      case dispatchType(sourceName, GET_LIST_FAIL):
        return {
          ...state,
          loading: false,
          error: action.payload.message
        };
      case dispatchType(sourceName, ON_CHANGE_PAGE):
        return { ...state, page: action.payload };
      case dispatchType(sourceName, ON_CHANGE_ROWS_PER_PAGE):
        return { ...state, perPage: action.payload, page: 1, data: null };
      case dispatchType(sourceName, ON_SEARCH_CHANGE):
        return { ...state, search: action.payload, page: 1, data: null };
      case dispatchType(sourceName, ON_FILTER_CHANGE):
        return { ...state, filters: action.payload, page: 1, data: null };
      case dispatchType(sourceName, COLUMN_SORT_CHANGE): {
        const { column, direction, hard } = action.payload;
        const sort = {};
        if (column && direction) {
          if (hard) {
            sort[column] = direction;
          } else {
            objectPath.set(sort, column, direction);
          }
        }
        return { ...state, sort };
      }
      default:
        return state;
    }
  };

export default dataTableReducer;
