import enGB from './en';
import ukUA from './ua';

const DEFAULT_TRANSLATION = 'UA';

export const getLanguage = () => {
  return DEFAULT_TRANSLATION;
};

const translations = {
  UA: ukUA,
  EN: enGB
};

const chosenLanguage = getLanguage();

const chosenTranslation = translations[chosenLanguage] || translations[DEFAULT_TRANSLATION];

export default chosenTranslation;
