import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Preloader from 'components/Preloader';
const CookieConsent = lazy(() => import('components/CookieConsent'));
const Home = lazy(() => import('pages/Home'));
const Professions = lazy(() => import('pages/Professions'));
const Profession = lazy(() => import('pages/Profession'));
const SignInScreen = lazy(() => import('pages/SignIn'));
const SignUpScreen = lazy(() => import('pages/SignUp'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const Profile = lazy(() => import('pages/Profile'));
const Posts = lazy(() => import('pages/Posts'));
const Post = lazy(() => import('pages/Post'));
const Policy = lazy(() => import('pages/Policy'));
const Company = lazy(() => import('pages/Company'));
const Consultants = lazy(() => import('pages/Consultants'));
const Consultant = lazy(() => import('pages/Consultant'));
const Mentors = lazy(() => import('pages/Mentors'));
const Mentor = lazy(() => import('pages/Mentor'));
const RefundPolicy = lazy(() => import('pages/Policy/Refund'));
const PrivacyPolicy = lazy(() => import('pages/Policy/Privacy'));
const CookiePolicy = lazy(() => import('pages/Policy/Cookie'));
const Order = lazy(() => import('pages/Order'));
const Orders = lazy(() => import('pages/Orders'));
const Page404 = lazy(() => import('pages/404'));

const RouterWrapper = () => (
  <Suspense fallback={<Preloader />}>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sign-in" element={<SignInScreen />} />
        <Route path="/sign-up" element={<SignUpScreen />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/forgot-password" element={<ResetPassword />} />
        <Route path="/professions" element={<Professions />} />
        <Route path="/professions/:id" element={<Profession />} />
        <Route path="/articles" element={<Posts />} />
        <Route path="/articles/:id" element={<Post />} />
        <Route path="/company/:id" element={<Company />} />
        <Route path="/consultants" element={<Consultants />} />
        <Route path="/consultants/:id" element={<Consultant />} />
        <Route path="/mentors" element={<Mentors />} />
        <Route path="/mentors/:id" element={<Mentor />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/policy/refund" element={<RefundPolicy />} />
        <Route path="/policy/privacy" element={<PrivacyPolicy />} />
        <Route path="/policy/cookie" element={<CookiePolicy />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/orders/:id" element={<Order />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <CookieConsent />
    </Router>
  </Suspense>
);

export default RouterWrapper;
