const isMobile = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < 768;
  }
  return false;
};

const initialState = {
  openDrawer: false,
  user: null,
  isMobile: isMobile()
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_OPEN_DRAWER':
      return { ...state, openDrawer: action.payload };
    case 'SET_LOCAL_SIGN_IN_REDIRECT':
      return { ...state, localSignInRedirect: action.payload };
    case 'SET_USER':
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
