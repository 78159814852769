import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  sendPasswordResetEmail
} from 'firebase/auth';
import storage from 'helpers/storage';
import config from 'config';

const app = initializeApp(config.firebaseConfig);

const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

const getUserInfo = getAuth(app);

const subscribe = (callback) => {
  app.authStateSubscription = auth.onAuthStateChanged((user) => {
    if (user && user?.emailVerified) {
      user
        .getIdToken(true)
        .then((idToken) => {
          storage.setItem('token', idToken);
          callback(user);
        })
        .catch(() => {
          storage.removeItem('token');
          callback(null);
        });
    }
  });
};

export {
  auth,
  googleProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
  getUserInfo,
  subscribe,
  signOut
};
