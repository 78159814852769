import config from 'config';
import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import reducer from 'reducers';
import { IS_SNAP } from 'variables/main';

const {
  application: { environment }
} = config;

const createStore = () =>
  configureStore({
    reducer,
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers(),
    middleware: () =>
      [
        routerMiddleware(),
        thunk,
        environment !== 'prod' && !IS_SNAP && createLogger({ collapsed: true })
      ].filter(Boolean),
    devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  });

export default createStore;
