import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import MainLayout from 'layouts/MainLayout';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const Content = styled('section')(({ theme }) => ({
  padding: '40px 100px',
  ...theme.wrapper,
  minHeight: '100vh',
  [theme.breakpoints.down('lg')]: {
    padding: 50
  },
  [theme.breakpoints.down('md')]: {
    padding: 30
  },
  [theme.breakpoints.down('sm')]: {
    padding: 20
  }
}));

const Title = styled(Typography)({
  marginBottom: 20,
  fontSize: 36,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
});

const SubTitle = styled(Typography)({
  fontSize: 24,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
});

const ServiceMessage = ({ children }) => {
  const t = useTranslate('ERRORS');
  const error = useSelector((state) => state?.error?.serviceMessage || null);

  if (!error) {
    return children;
  }

  return (
    <MainLayout title={t('Title')}>
      <Content>
        <Title variant="h1">{t(error.message)}</Title>
        <SubTitle variant="h2">{t(error.message + ' subtitle')}</SubTitle>
      </Content>
    </MainLayout>
  );
};

export default ServiceMessage;
