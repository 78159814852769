import React from 'react';
import { setUser } from 'actions/auth';
import { useDispatch } from 'react-redux';
import { subscribe } from 'services/firebase';

const Auth = ({ children }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    subscribe((user) => {
      dispatch(setUser(user));
    });
  }, [dispatch]);

  return children;
};

export default Auth;
