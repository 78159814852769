import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from 'components/Snackbars/Snackbar';
import Box from '@mui/material/Box';
import { closeError } from 'actions/error';
import { styled } from '@mui/system';

const BoxStyled = styled(Box)(({ mobile }) => ({
  top: mobile === 'true' ? 0 : 4,
  borderRadius: 0,
  right: 0,
  position: 'fixed',
  zIndex: 5000,
  maxWidth: 400,
  width: '100%',
  '& > div': {
    borderRadius: 0
  }
}));

const SnackbarWrapper = () => {
  const dispatch = useDispatch();
  const errors = useSelector((state) => state?.error?.list || []);
  const isMobile = useSelector((state) => state.app.isMobile);

  const handleClose = React.useCallback(
    (errorIndex) => dispatch(closeError(errorIndex)),
    [dispatch]
  );

  return (
    <BoxStyled mobile={`${isMobile}`}>
      {(errors || []).map((error, index) => (
        <Snackbar key={index} error={error} onClose={(e) => handleClose(index)} />
      ))}
    </BoxStyled>
  );
};

export default SnackbarWrapper;
