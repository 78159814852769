import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import ScrollToTop from 'components/ScrollToTop';

const MainLayoutContainer = styled('article')(({ theme, grey }) => ({
  backgroundColor: grey ? '#F5F5F5' : theme.palette.background.default,
  position: 'relative',
  margin: '0 auto'
}));

const MainLayout = ({ children, grey }) => {
  return (
    <MainLayoutContainer grey={grey} id={'main_layout'}>
      {children}
      <ScrollToTop />
    </MainLayoutContainer>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default MainLayout;
