import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'react-translate';
import { IconButton } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import isCyrillic from 'helpers/isCyrillic';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ErrorSnackbar = ({
  closeDelay = 4000,
  error: { message, variant, autoClose = true },
  onClose
}) => {
  const t = useTranslate('ERRORS');
  const errors = useSelector((state) => state?.error?.list || []);

  const handleClose = React.useCallback((e) => onClose(e), [onClose]);

  React.useEffect(() => {
    if (!autoClose) return;

    setTimeout(() => {
      if (!errors.length) return;

      if (!['default', 'error', 'permanentWarning'].includes(variant)) {
        onClose();
      }
    }, closeDelay);
  }, [autoClose, errors, closeDelay, onClose, variant]);

  return (
    <Alert
      icon={false}
      severity={variant || 'error'}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }>
      {isCyrillic(message) ? message : t(message)}
    </Alert>
  );
};

export default ErrorSnackbar;
