import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { TranslatorProvider } from 'react-translate';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import SnackbarWrapper from 'components/Snackbars';
import ServiceMessage from 'components/ServiceMessage';
import Auth from 'components/Auth';
import Routes from 'routes';
import translation from 'translation';
import theme from 'theme';
import store from 'store';
import 'assets/css/main.css';
import 'assets/css/fonts.css';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={createTheme(theme)}>
      <TranslatorProvider translations={translation}>
        <Provider store={store}>
          <Auth>
            <ServiceMessage>
              <Routes />
              <SnackbarWrapper />
            </ServiceMessage>
          </Auth>
        </Provider>
      </TranslatorProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

root.render(<App />);
