const initialState = {
  state: {}
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CONSULTANT_INFO_SUCCESS':
      const id = action?.payload?._id;
      return { ...state, state: { ...state.state, [id]: action.payload } };
    default:
      return state;
  }
};

export default rootReducer;
