const initialState = {
  state: {}
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LAYOUT_SUCCESS':
      const url = action?.url.split('/').pop();
      return { ...state, state: { ...state.state, [url]: action.payload } };
    default:
      return state;
  }
};

export default rootReducer;
