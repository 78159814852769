const mapDataDefault = (payload) => {
  const { meta } = payload;
  const { currentPage, perPage, total } = meta || {};

  return {
    data: payload,
    page: currentPage,
    perPage: perPage,
    count: total
  };
};

export default mapDataDefault;
